import React from "react"

import Layout from "../components/layout"

const ImpressumPage = () => {
  return (
    <Layout title="Impressum/Datenschutz">
      <section>
        <h2>Impressum</h2>

        <h4>Angaben gem&auml;&szlig; &sect; 5 TMG</h4>
        <p>
          ZV Hohenlohe
          <br />
          Im St&auml;dtle 3<br />
          74541 Vellberg
        </p>

        <p>
          Handelsregister: 747654
          <br />
          Registergericht: Stuttgart
        </p>

        <p>
          <strong>Vertreten durch:</strong>
          <br />
          Dietrich Wollenzien
        </p>

        <h4>Kontakt</h4>
        <p>
          Telefon: 016093138278
          <br />
          E-Mail: museumsgasthof@web.de
        </p>

        <h4>Umsatzsteuer-ID</h4>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect;27 a
          Umsatzsteuergesetz:
          <br />
          84060/60306
        </p>

        <h4>Streitschlichtung</h4>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr
          </a>
          .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <h4>Haftung f&uuml;r Inhalte</h4>
        <p>
          Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG
          f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
          gespeicherte fremde Informationen zu &uuml;berwachen oder nach
          Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit
          hinweisen.
        </p>
        <p>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon
          unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab
          dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
          m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
          werden wir diese Inhalte umgehend entfernen.
        </p>
        <h4>Haftung f&uuml;r Links</h4>
        <p>
          Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf
          deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir
          f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen.
          F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige
          Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
          Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche
          Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte
          waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        </p>
        <p>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>
        <h4>Urheberrecht</h4>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die
          Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
          Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
          bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
          Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den
          privaten, nicht kommerziellen Gebrauch gestattet.
        </p>
        <p>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>

        <h2>Datenschutzerkl&auml;rung</h2>
        <h4>1. Datenschutz auf einen Blick</h4>
        <h4>Allgemeine Hinweise</h4>
        <p>
          Die folgenden Hinweise geben einen einfachen &Uuml;berblick
          dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
          diese Website besuchen. Personenbezogene Daten sind alle Daten, mit
          denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
          Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie
          unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
        </p>
        <h4>Datenerfassung auf dieser Website</h4>
        <p>
          <strong>
            Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser
            Website?
          </strong>
        </p>
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Impressum
          dieser Website entnehmen.
        </p>
        <p>
          <strong>Wie erfassen wir Ihre Daten?</strong>
        </p>
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie
          in ein Kontaktformular eingeben.
        </p>
        <p>
          Andere Daten werden automatisch beim Besuch der Website durch unsere
          IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B.
          Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
          Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
          betreten.
        </p>
        <p>
          <strong>Wof&uuml;r nutzen wir Ihre Daten?</strong>
        </p>
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur
          Analyse Ihres Nutzerverhaltens verwendet werden.
        </p>
        <p>
          <strong>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</strong>
        </p>
        <p>
          Sie haben jederzeit das Recht unentgeltlich Auskunft &uuml;ber
          Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
          personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
          Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen.
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie
          sich jederzeit unter der im Impressum angegebenen Adresse an uns
          wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der
          zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
        </p>
        <p>
          Au&szlig;erdem haben Sie das Recht, unter bestimmten Umst&auml;nden
          die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten
          zu verlangen. Details hierzu entnehmen Sie der
          Datenschutzerkl&auml;rung unter &bdquo;Recht auf Einschr&auml;nkung
          der Verarbeitung&ldquo;.
        </p>
        <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
        <h4>Externes Hosting</h4>
        <p>
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
          v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Webseitenzugriffe und sonstige
          Daten, die &uuml;ber eine Website generiert werden, handeln.
        </p>
        <p>
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf&uuml;llung
          gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6
          Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        </p>
        <p>
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erf&uuml;llung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
        </p>
        <p>
          <strong>
            Abschluss eines Vertrages &uuml;ber Auftragsverarbeitung
          </strong>
        </p>
        <p>
          Um die datenschutzkonforme Verarbeitung zu gew&auml;hrleisten, haben
          wir einen Vertrag &uuml;ber Auftragsverarbeitung mit unserem Hoster
          geschlossen.
        </p>
        <h4>3. Allgemeine Hinweise und Pflichtinformationen</h4>
        <h4>Datenschutz</h4>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
          Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
          vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften
          sowie dieser Datenschutzerkl&auml;rung.
        </p>
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
          Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
          wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem
          Zweck das geschieht.
        </p>
        <p>
          Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
          (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken
          aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff
          durch Dritte ist nicht m&ouml;glich.
        </p>
        <h4>Hinweis zur verantwortlichen Stelle</h4>
        <p>
          Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
          Website ist:
        </p>
        <p>
          Dietrich Wollenzien <br />
          Im St&auml;dtle 3<br />
          74541 Vellberg
        </p>

        <p>
          Telefon: 016093138278
          <br />
          E-Mail: museumsgasthof@web.de
        </p>
        <p>
          Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
          Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
          E-Mail-Adressen o. &Auml;.) entscheidet.
        </p>
        <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
        <p>
          Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
          ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine
          bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
          formlose Mitteilung per E-Mail an uns. Die Rechtm&auml;&szlig;igkeit
          der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
          unber&uuml;hrt.
        </p>
        <h4>
          Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen
          sowie gegen Direktwerbung (Art. 21 DSGVO)
        </h4>
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE
          SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG
          IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH
          F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE
          JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
          ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH
          EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
          MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
          SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN,
          DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE
          VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG
          VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
        </p>
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
          VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
          DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
          SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
          WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
          MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
          ABS. 2 DSGVO).
        </p>
        <h4>Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde</h4>
        <p>
          Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
          Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
          insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts,
          ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen
          Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet
          anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <h4>Recht auf Daten&uuml;bertragbarkeit</h4>
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an
          sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren
          Format aush&auml;ndigen zu lassen. Sofern Sie die direkte
          &Uuml;bertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <h4>SSL- bzw. TLS-Verschl&uuml;sselung</h4>
        <p>
          Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
          &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
          oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
          bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung
          erkennen Sie daran, dass die Adresszeile des Browsers von
          &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem
          Schloss-Symbol in Ihrer Browserzeile.
        </p>
        <p>
          Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen
          die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten
          mitgelesen werden.
        </p>
        <h4>Auskunft, L&ouml;schung und Berichtigung</h4>
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der im
          Impressum angegebenen Adresse an uns wenden.
        </p>
        <h4>Recht auf Einschr&auml;nkung der Verarbeitung</h4>
        <p>
          Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
          jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden
          F&auml;llen:
        </p>
        <ul>
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel
            Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der
            Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten
            unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
            L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
            verlangen.
          </li>
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie
            sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
            Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
            L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
          </li>
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
            haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
            vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
            &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
        </ul>
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
          eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer
          Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
          Geltendmachung, Aus&uuml;bung oder Verteidigung von
          Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
          nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines
          wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union
          oder eines Mitgliedstaats verarbeitet werden.
        </p>
        <h4>Widerspruch gegen Werbe-E-Mails</h4>
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten
          Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich
          angeforderter Werbung und Informationsmaterialien wird hiermit
          widersprochen. Die Betreiber der Seiten behalten sich
          ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten
          Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
        </p>
        <h4>4. Datenerfassung auf dieser Website</h4>
        <h4>Anfrage per E-Mail, Telefon oder Telefax</h4>
        <p>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
          (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </p>
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
          Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
          vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
          &uuml;brigen F&auml;llen beruht die Verarbeitung auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder auf unseren
          berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein
          berechtigtes Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen haben.
        </p>
        <p>
          Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
          verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
          Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die
          Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
          Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen
          &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
          unber&uuml;hrt.
        </p>
        <h4>5. Plugins und Tools</h4>
        <h4>Google Web Fonts</h4>
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
          einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in
          ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
        </p>
        <p>
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
          den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
          dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese Website aufgerufen
          wurde. Die Nutzung von Google WebFonts erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. f DSGVO. Der Webseitenbetreiber hat ein berechtigtes
          Interesse an der einheitlichen Darstellung des Schriftbildes auf
          seiner Webseite. Sofern eine entsprechende Einwilligung abgefragt
          wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt
          die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1
          lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>
          Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird eine
          Standardschrift von Ihrem Computer genutzt.
        </p>
        <p>
          Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
          <a
            href="https://developers.google.com/fonts/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/fonts/faq
          </a>{" "}
          und in der Datenschutzerkl&auml;rung von Google:{" "}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h4>Google Maps</h4>
        <p>
          Diese Seite nutzt &uuml;ber eine API den Kartendienst Google Maps.
          Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon
          House, Barrow Street, Dublin 4, Irland.
        </p>
        <p>
          Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP
          Adresse zu speichern. Diese Informationen werden in der Regel an einen
          Server von Google in den USA &uuml;bertragen und dort gespeichert. Der
          Anbieter dieser Seite hat keinen Einfluss auf diese
          Daten&uuml;bertragung.
        </p>
        <p>
          Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote und an einer leichten
          Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
          stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
          DSGVO dar.
        </p>
        <p>
          Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerkl&auml;rung von Google:{" "}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <p>
          Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
        </p>
      </section>
    </Layout>
  )
}

export default ImpressumPage
